var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WaBtn'),_c('MainBanner'),_c('div',{staticClass:"py-10 bg-gray-50"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"pt-5 md:flex"},_vm._l((_vm.brands),function(image){return _c('div',{key:image,staticClass:"w-2/3 pb-2 mx-auto md:w-1/4"},[_c('img',{staticClass:"mx-auto w-52",attrs:{"src":image,"alt":""}})])}),0)])]),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(1),_c('carousel',{attrs:{"autoplay":false,"loop":true,"responsive":{
          0: { items: 2, nav: false },
          700: { items: 3, nav: false },
          1000: { items: 4, nav: false },
        }}},_vm._l((_vm.moDels),function(item,i){return _c('div',{key:i,staticClass:"mx-2 md:px-2"},[_c('div',{staticClass:"py-6 border-2 border-gray-200 shadow-lg"},[_c('img',{staticClass:"mx-auto text-center",attrs:{"src":item.image,"alt":item.alt}})])])}),0)],1)]),_c('div',{staticClass:"py-10 bg-center bg-no-repeat bg-cover md:bg-left",staticStyle:{"background-image":"url('/images/bg-photocopier-2.png')"}},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"text-2xl font-semibold leading-tight text-center text-white"},[_vm._v(" Brand New & Refurbished Photocopier Available! ")]),_c('div',{staticClass:"flex flex-wrap pt-6 pb-12 lg:max-w-4xl lg:mx-auto"},_vm._l((_vm.provide),function(item,i){return _c('div',{key:i,staticClass:"w-1/2 p-2 text-center md:w-1/4"},[_c('img',{staticClass:"w-20 mx-auto",attrs:{"src":item.image,"alt":item.alt}}),_c('h1',{staticClass:"pt-3 text-sm leading-tight text-white"},[_vm._v(" "+_vm._s(item.alt)+" ")])])}),0),_vm._m(2)])]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"py-10 bg-center bg-no-repeat bg-cover",staticStyle:{"background-image":"url('/images/bg-choose.jpg')"}},[_c('div',{staticClass:"container"},[_c('h1',{staticClass:"text-2xl font-semibold text-center text-white"},[_vm._v(" Why Choose Us ")]),_c('div',{staticClass:"flex flex-wrap pt-10"},_vm._l((_vm.choose),function(item,i){return _c('div',{key:i,staticClass:"w-1/2 pb-2 text-center md:w-1/3 lg:w-1/6"},[_c('img',{staticClass:"w-16 mx-auto",attrs:{"src":item.image,"alt":item.alt}}),_c('h1',{staticClass:"pt-4 text-sm font-semibold text-white"},[_vm._v(" "+_vm._s(item.alt)+" ")])])}),0)])]),_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"container py-6 mx-auto text-center open-normal"},[_vm._m(5),_c('carousel',{attrs:{"autoplayTimeout":1500,"autoplay":true,"loop":true,"responsive":{
          0: { items: 2, nav: false },
          700: { items: 3, nav: false },
          1000: { items: 4, nav: false },
        }}},_vm._l((_vm.clienTlogo),function(item,i){return _c('div',{key:i,staticClass:"md:px-2"},[_c('div',{staticClass:"px-3 py-6"},[_c('img',{staticClass:"mx-auto text-center",attrs:{"src":item.image,"alt":item.alt}})])])}),0)],1)]),_vm._m(6),_vm._m(7),_vm._m(8),_c('CopyRight')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"text-2xl font-semibold text-center text-gray-700"},[_vm._v(" Multifunction "),_c('span',{staticClass:"text-hy-liteblue"},[_vm._v("Copier Brands")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"pb-4 text-2xl font-semibold text-center text-gray-700"},[_vm._v(" Machine "),_c('span',{staticClass:"text-hy-liteblue"},[_vm._v("Models")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-1"},[_c('h1',{staticClass:"py-2 mx-auto font-semibold text-center text-white transition duration-300 ease-in-out hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl"},[_c('a',{attrs:{"href":"https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you"}},[_vm._v("Ask For A Quote Now!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-20 mx-auto bg-white"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"lg:flex lg:items-center"},[_c('div',{staticClass:"text-gray-800 lg:w-1/2 lg:px-3"},[_c('div',{staticClass:"pb-6 lg:pb-0"},[_c('img',{staticClass:"mx-auto drop-shadow-md hover:shadow-xl",attrs:{"src":"/images/sub-brand-2.jpg","alt":"About Us"}})])]),_c('div',{staticClass:"pt-5 lg:w-1/2 lg:pt-0 lg:px-3"},[_c('img',{staticClass:"mx-auto drop-shadow-md hover:shadow-xl",attrs:{"src":"/images/sub-brand-1.jpg","alt":"About Us"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10 mx-auto bg-blue-1"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"lg:flex lg:items-center"},[_c('div',{staticClass:"lg:w-1/2 lg:pl-10 lg:order-2"},[_c('div',{staticClass:"pb-6 text-center lg:text-left"},[_c('h1',{staticClass:"pb-4 text-2xl font-semibold text-gray-100 lg:text-4xl"},[_vm._v(" Unsure Of Which Copier Or Plan Is Most Suitable? ")]),_c('p',{staticClass:"text-center text-gray-100 lg:text-left"},[_vm._v(" We can help you make the right choice, by giving you the best options that suits your requirements and budget. "),_c('br'),_c('br'),_vm._v(" Just answer a few questions and we would be able to give you a few recommendations. ")])]),_c('div',{staticClass:"flex flex-wrap justify-center space-y-5 lg:justify-start md:space-y-0 md:space-x-3"},[_c('p',[_c('a',{staticClass:"px-4 py-2 mx-auto font-semibold text-center text-white uppercase transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl",attrs:{"href":"https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you"}},[_vm._v("get price quote")])]),_c('p',[_c('a',{staticClass:"px-4 py-2 mx-auto font-semibold text-center text-white capitalize transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl",attrs:{"href":"https://wa.me/60123117001"}},[_vm._v("Questions? call 012-311 7001")])])])]),_c('div',{staticClass:"pt-5 lg:w-1/2 lg:pt-0"},[_c('img',{staticClass:"mx-auto",attrs:{"src":"/images/suitable-1.jpg","alt":"About Us"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h1',{staticClass:"pb-6 text-2xl font-semibold text-center text-gray-700"},[_vm._v(" Over The Years "),_c('span',{staticClass:"text-hy-liteblue"},[_vm._v("We Served")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-blue-1"},[_c('div',{staticClass:"container py-10 mx-auto"},[_c('div',{staticClass:"lg:flex lg:items-center"},[_c('div',{staticClass:"text-white lg:w-1/2 lg:pr-4"},[_c('div',{staticClass:"pb-6 text-center lg:text-left"},[_c('h1',{staticClass:"text-2xl font-semibold text-white"},[_vm._v(" Who We Are ")]),_c('p',{staticClass:"pb-3 text-lg font-semibold leading-tight text-white"},[_vm._v(" We Are Dedicated Copier Specialists ")]),_c('p',{staticClass:"text-sm font-semibold text-white"},[_vm._v(" Copier Rental | Copier Malaysia | Copier Machine | Copier Spare Parts| Copier Supply | Copier Recond | ")])]),_c('p',{staticClass:"text-center lg:text-left"},[_vm._v(" Hytech Office Automation (M) Sdn Bhd is a privately-owned company incorporated in 1999 under the Companies Act 1965 of Malaysia. With 25 years of experience in this industry, we are proud to be one of the "),_c('span',{staticClass:"open-bold"},[_vm._v("Dedicated Copier Specialists in Malaysia.")]),_c('br'),_c('br'),_vm._v(" We provide Extensive Product Range of top-quality Fuji Xerox black-and-white and colour photocopiers, from small and medium to high-volume capacity machines. We also represent other renowned brands of photocopiers, laser printer, large format printers, projectors & smart board such as Canon, Hewlett-Packard(HP), Epson, Panasonic and Maxhub, as well as our own house brand of projector screens, DP Screen ")])]),_c('div',{staticClass:"pt-5 lg:w-1/2 lg:pt-0"},[_c('img',{staticClass:"mx-auto",attrs:{"src":"/images/about-us.jpg","alt":"About Us"}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10 bg-center bg-no-repeat bg-cover",staticStyle:{"background-image":"url('/images/enquiry-bg.jpg')"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"p-3 bg-hy-liteblue bg-opacity-70 lg:max-w-3xl lg:mx-auto"},[_c('h1',{staticClass:"pt-5 pb-4 text-2xl font-semibold text-center text-white"},[_vm._v("Send An Enquiry")]),_c('p',{staticClass:"pb-4 text-base font-medium text-center text-white"},[_vm._v(" Fill out the form below and we'll get back to you as soon as possible ")]),_c('iframe',{staticStyle:{"min-height":"580px","height":"inherit","overflow":"auto"},attrs:{"allowTransparency":"true","width":"100%","id":"contactform123","name":"contactform123","marginwidth":"0","marginheight":"0","frameborder":"0","src":"https://feedback.activamedia.com.sg/my-contact-form-5753840.html"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10 bg-white"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md:flex md:items-center"},[_c('div',{staticClass:"text-gray-600 md:w-1/2"},[_c('img',{staticClass:"mx-auto w-42",attrs:{"src":"/images/logo.png","alt":""}}),_c('div',{staticClass:"flex items-center pt-6"},[_c('i',{staticClass:"pr-2 text-xl text-hy-liteblue fa-solid fa-building"}),_c('h1',{staticClass:"text-lg leading-tight"},[_vm._v("Hytech Office Automation (M) Sdn Bhd")])]),_c('div',{staticClass:"flex items-start pt-5"},[_c('i',{staticClass:"pr-2 text-xl text-hy-liteblue fa-solid fa-location-dot"}),_c('h1',{staticClass:"text-base leading-tight"},[_vm._v("No. 4, Jalan Sayang 3, Taman Rasa Sayang, 43200 Cheras, Selangor")])]),_c('div',{staticClass:"flex items-start pt-5"},[_c('i',{staticClass:"pr-2 text-xl text-hy-liteblue fa-solid fa-phone"}),_c('h1',{staticClass:"text-base leading-tight"},[_c('a',{attrs:{"href":"tel:+60123117001"}},[_vm._v(" 012-3117001")]),_vm._v(" | "),_c('a',{attrs:{"href":"tel:+60390802000"}},[_vm._v("03-90802000")])])]),_c('div',{staticClass:"flex items-start pt-2"},[_c('i',{staticClass:"pr-2 text-xl text-hy-liteblue fa-brands fa-whatsapp"}),_c('h1',{staticClass:"text-base leading-tight"},[_c('a',{attrs:{"href":"https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you"}},[_vm._v(" 012-3117001")])])])]),_c('div',{staticClass:"pt-5 md:w-1/2 md:pt-0"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.0894815780784!2d101.75316500049833!3d3.0707633544744857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4a782d0a8b8d%3A0x15e57dd997a86490!2sHytech%20Office%20Automation%20(M)%20Sdn%20Bhd!5e0!3m2!1sen!2smy!4v1607997350676!5m2!1sen!2smy","width":"100%","height":"250","frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})])])])])
}]

export { render, staticRenderFns }
<template>
  <div>
    <!-- main banner -->
      <div class="hidden bg-opacity-50 bg-lite-blue lg:block">
        <div class="py-20 lg:relative lg:py-32 lg:flex lg:justify-center lg:items-start">
          <div class="md:w-2/3 lg:w-1/2 lg:mx-0 lg:ml-auto md:mx-auto">
            <img src="/images/banner-product.png" alt="" class="" />
          </div>

          <div class="lg:absolute lg:top-0 lg:pt-32 lg:left-0 lg:h-full lg:block lg:bg-white lg:bg-opacity-50 lg:w-1/2">
            <div class="pl-10 xl:max-w-3xl xl:ml-auto">
              <img src="/images/logo.png" alt="" class="w-32 pb-4 mx-auto md:w-40 md:mx-0" />
              <div class="text-black">
                <h1 class="pb-2 text-xl font-semibold lg:text-2xl">
                  YOUR TOTAL OFFICE SOLUTIONS
                </h1>
                <div class="bg-black w-10 h-0.5"></div>
                <p
                  class="pt-2 text-3xl font-bold leading-tight text-gray-900 md:pt-5 md:text-4xl lg:text-5xl xl:text-6xl">
                  Discover
                  <span class="text-hy-darkblue">Our Attractive Rental</span>
                  Options Today!
                </p>
              </div>
              <div class="flex flex-wrap pt-4 space-x-2 md:pt-8">
                <h1
                  class="py-2 mx-auto font-semibold text-center text-white transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl">
                  <a
                    href="https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you">Special
                    Deals</a>
                </h1>
                <h1
                  class="py-2 mx-auto font-semibold text-center text-white transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl">
                  <a href="https://wa.me/60123117001">WhatsApp Us</a>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

    <!-- mobile-tagline  -->
    <div class="py-10 bg-white lg:hidden">
      <div class="container">
        <img src="/images/logo.png" alt="" class="w-32 pb-4 mx-auto md:w-40 md:mx-0" />
        <div class="text-gray-700">
          <h1 class="pb-2 text-xl font-semibold text-center md:text-left md:text-3xl">
            YOUR TOTAL OFFICE SOLUTIONS
          </h1>
          <div class="bg-gray-500 w-10 mx-auto md:mx-0 h-0.5"></div>
          <p class="pt-2 text-2xl font-bold leading-tight text-center text-blue-500 md:pt-5 md:text-5xl md:text-left">
            Discover
            <span class="text-hy-darkblue">Our Attractive Rental</span> Options
            Today!
          </p>
        </div>
        <div class="pt-4 md:pt-8 md:flex md:flex-wrap md:space-x-2">
          <h1
            class="py-2 mx-auto mb-2 font-semibold text-center text-white transition duration-300 ease-in-out md:mb-0 md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl">
            <a
              href="https://wa.me/60123117001?text=Hi,%20I%20would%20like%20to%20know%20more%20about%20the%20copier%20promotion%20package,%20thank%20you">Special
              Deals</a>
          </h1>
          <h1
            class="py-2 mx-auto font-semibold text-center text-white transition duration-300 ease-in-out md:mx-0 hover:bg-blue-500 w-52 bg-hy-liteblue rounded-xl">
            <a href="https://wa.me/60123117001">WhatsApp Us</a>
          </h1>
        </div>
      </div>
    </div>

      <div class="bg-opacity-50 bg-lite-blue lg:hidden">
        <div class="py-20 lg:relative lg:py-32 lg:flex lg:justify-center lg:items-start">
          <div class="md:w-2/3 lg:w-1/2 lg:mx-0 lg:ml-auto md:mx-auto">
            <img src="/images/banner-product.png" alt="" class="" />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
